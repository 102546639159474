.small-loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.small-loader div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-animation: small-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation: small-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.small-loader div:nth-child(1) {
  left: 6px;
  -webkit-animation-delay: -0.24s;
  animation-delay: -0.24s;
}

.small-loader div:nth-child(2) {
  left: 26px;
  -webkit-animation-delay: -0.12s;
  animation-delay: -0.12s;
}

.small-loader div:nth-child(3) {
  left: 45px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

@-webkit-keyframes small-loader {
  0% {
    top: 6px;
    height: 51px;
  }
  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}

@keyframes small-loader {
  0% {
    top: 6px;
    height: 51px;
  }
  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}
