.consent-form-medic-team-select-label {
  font-weight: bold;
}

.consent-form-medic-team-select-option {
  margin-left: 15px;
}

.consent-form-option-disabled {
  opacity: 0.3;
}
