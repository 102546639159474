.ec-pdf-viewer-container {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.ec-pdf-viewer-container-hidden {
  display: none;
}

.ec-pdf-viewer-container canvas,
.ec-pdf-viewer-container svg,
.ec-pdf-viewer-container .react-pdf__Page__svg {
  width: 100% !important;
  max-width: 100% !important;
  height: unset !important;
}

.ec-pdf-viewer-custom-navigation {
  background-color: #74798c;
  height: 35px;
  margin-bottom: 15px;
  padding: 0 15px;
}

.ec-pdf-viewer-custom-navigation > button {
  cursor: pointer;
  margin: 0;
  text-decoration: none;
}


.ec-pdf-viewer-custom-navigation > button:hover {
  color: white;
  text-transform: none;
}

.ec-pdf-viewer-navigation-text {
  color: white;
  white-space: nowrap;
  font-size: 13px;
}
