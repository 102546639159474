#infoView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 60px;
  position: fixed;
  padding: 5px 20px;
  font-size: 19px;
  text-align: center;
  color: #f5f2f2;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  z-index: 10;
  bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

#infoView > div {
  text-align: center;
  width: 90%;
}

#infoView.display-info {
  opacity: 1;
}

#infoView.hide-info {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  z-index: -1 !important;
  pointer-events: none;
}

#infoView .loading-background {
  -webkit-animation: loading-animation 5s linear infinite;
  animation: loading-animation 5s linear infinite;
}

#infoView .remove-info {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 145%;
  color: white;
  opacity: 0.5;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

#infoView .remove-info:hover {
  -webkit-transform: translateY(-50%) scale(1.2, 1.2);
  transform: translateY(-50%) scale(1.2, 1.2);
  opacity: 1;
}
