.ec-dropdown-list-question-choice {
  font-family: 'NunitoSans Regular', sans-serif;
}

.form-item-error-text-dropdown {
  font-family: 'NunitoSans Regular', sans-serif;
  border-radius: 2px;
  font-size: 17, 9px;
  margin-left: 10px;
  margin-top: 50px;
  margin-bottom: 5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: left;
  color: red;
}

.dropdown-list-input-text {
  width: 789px;
  height: 40px;
  margin-right: 5px;
  margin-left: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 2px;
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #79828b;
}

.dropdown-list-input-text:hover {
  border: solid 1px #cfcfcf;
}

.dropdown-list-input-text::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 16px;
  border: 7px solid transparent;
  border-color: #fff transparent transparent transparent;
}
