@for $i from 6 through 100 {
  .order-#{$i} {
    order: #{$i} !important;
  }
}

@for $spacer from 0 through 5 {
  .mx-#{$spacer} {
    margin-left: ($spacer * 0.25rem) !important;
    margin-right: ($spacer * 0.25rem) !important;
  }
  .my-#{$spacer} {
    margin-top: ($spacer * 0.25rem) !important;
    margin-bottom: ($spacer * 0.25rem) !important;
  }
  .mt-#{$spacer} {
    margin-top: ($spacer * 0.25rem) !important;
  }
  .mr-#{$spacer} {
    margin-right: ($spacer * 0.25rem) !important;
  }
  .mb-#{$spacer} {
    margin-bottom: ($spacer * 0.25rem) !important;
  }
  .ml-#{$spacer} {
    margin-left: ($spacer * 0.25rem) !important;
  }
  .pt-#{$spacer} {
    padding-top: ($spacer * 0.25rem) !important;
  }
  .pr-#{$spacer} {
    padding-right: ($spacer * 0.25rem) !important;
  }
  .pb-#{$spacer} {
    padding-bottom: ($spacer * 0.25rem) !important;
  }
  .pl-#{$spacer} {
    padding-left: ($spacer * 0.25rem) !important;
  }
}

@for $spacer from 0 through 4 {
  .mh-#{$spacer * 25} {
    max-height: ($spacer * 25%) !important;
  }
  .mw-#{$spacer * 25} {
    max-width: ($spacer * 25%) !important;
  }
}
