/* ConsentHeader.jsx */
.ec-consentheader-main-container {
  padding-left: 28px;
  padding-right: 28px;
  margin-right: 15px;
}

.ec-consent-header-selects-container {
  display: flex;
}

.ec-consentheader-title-Input {
  width: 100%;
  height: 60px;
  border: none;
  background-color: #ffffff;
  outline: none;
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  color: #20253a;
}
.ec-consentheader-title-Input-static {
  height: 60px;
  border: none;
  background-color: #ffffff;
  outline: none;
  margin-bottom: 16px;
  width: 100%;
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  color: #20253a;
}

.ec-consentheader-desc-Input {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  outline: none;
  border: none;

  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #79828b;
}

.ec-consentheader-desc-Input-static {
  height: 40px;
  background-color: #ffffff;
  outline: none;
  border: none;

  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #79828b;
}

.ec-consentheader-type-Input {
  height: 40px;
  background-color: #ffffff;
  border: none;
  outline: none;
  margin-bottom: 32px;

  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #79828b;
}

.ec-consentheader-title-Input:hover {
  border-radius: 8px;
  border: solid 1px #cfcfcf;
}
.ec-consentheader-desc-Input:hover {
  border-radius: 8px;
  border: solid 1px #cfcfcf;
}
.ec-consentheader-type-Input:hover {
  border-radius: 8px;
  border: solid 1px #cfcfcf;
}
.ec-checkbox-text-consent-header {
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #79828b;
}

.ec-checkbox-text-consent-header span {
  margin-left: 5px;
}

.ec-consentheader-description-container,
.ec-consentheader-description-tag-input {
  height: 40px;
  background-color: #ffffff;
  outline: none;
  border: none;
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #79828b;
  margin: 5px 0;
}
.ec-consentheader-description-tag-input {
  height: 30px;
}
.ec-consentheader-description-tag {
  background-color: #00aecb;
  background-image: linear-gradient(#00d7e7, #00aecb);
  border-radius: 20px;
  display: inline-block;
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}
.ec-consentheader-description-tag a::before {
  content: ' ×';
}
