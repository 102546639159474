.ec-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(115, 115, 139, 0.09);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.ec-card-test {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(115, 115, 139, 0.09);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
