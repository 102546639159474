/* List.jsx*/
.ec-sortable-list-title-container {
  display: flex;
  align-items: center;
  background-color: #f5f7fa;
  padding-left: 10px;
}

.ec-sortable-list-title-container img {
  width: 20px;
  height: 20px;
}

.ec-sortable-list-title-container input {
  width: 100%;
  margin-left: 10px;
}

.ec-sortable-list-title-container img:last-child {
  margin-left: auto;
  margin-right: 10px;
}

.ec-sortable-list-title-container p {
  margin-left: 15px;
  align-self: center;
  font-family: 'NunitoSans Bold', sans-serif;
  margin-top: auto;
  margin-bottom: auto;
}

.ec-sortable-list-items-container {
  min-height: 20px;
}

.ec-sortable-item-bordered input {
  border: solid 1px #00aecb;
  height: 25px;
  font-size: 20px;
}

/* Item.jsx */

.ec-sortable-item-separator {
  height: 1px;
  background-color: #00aecb;
  margin: 10px 50px 10px 50px;
}

.ec-sortable-item {
  padding-left: 20px;
  border: none;
  background: white;
  display: flex;
  align-items: center;
  width: 100%;
}

.ec-sortable-item-dragged {
  border: solid 2px #00aecb;
}

.ec-sortable-item p {
  font-family: 'NunitoSans Regular', sans-serif;
  margin-left: 10px;
  width: 800px;
  margin-top: auto;
  margin-bottom: auto;
}

.ec-sortable-item input {
  font-family: 'NunitoSans Regular', sans-serif;
  margin-left: 10px;
  width: 100%;
  border: none;
  outline: none;
}

.ec-sortable-item-bordered-textarea {
  border: solid 1px #00aecb;
  outline: none;
  margin-left: 10px;
  width: 100%;
  border-radius: 8px;
  font-size: 20px;
  resize: none;
}

.ec-sortable-item-bordered input {
  border: solid 1px #00aecb;
  height: 25px;
  font-size: 20px;
}

.ec-item-bigger-text {
  font-size: 20px;
}

.ec-sortable-list-icons {
  margin-top: 5px;
}

.ec-sortable-list-button-icons {
  margin-top: 5px;
  outline: none;
  border: none;
  background-color: #f5f7fa;
  margin-left: auto;
}

.ec-sortable-item img {
  width: 10px;
  height: 10px;
}

.ec-sortable-item-delete-icon {
  width: 5%;
  display: flex;
  justify-content: flex-end;
}
