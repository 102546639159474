/* Menu.jsx*/
.createform-menu-container {
  margin-right: 20px;
  margin-top: 0px;
  width: 350px;
}

.createform-menu-container .ec-card .ec-primary-button {
  margin-left: 12%;
}

.createform-menu-container .ec-card {
  width: 258px;
  padding-bottom: 15px;
}

.createform-menu-card-title {
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.81;
  letter-spacing: -0.08px;
  color: #393d52;
  margin: 15px 0px 0px 27px;
}

.createform-menu-card-button {
  background: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.createform-menu-card-button img {
  fill: yellow;
}

.createform-menu-card-add-step-button-image {
  width: 22px;
  height: 22px;
}

/* Question.jsx */
.createform-menu-single-question-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 27px;
  cursor: grab;
}
.createform-menu-single-question-container:hover {
  background-color: #f8f8f8;
}
.createform-menu-single-question-container p {
  margin-bottom: 0px;
}
.createform-menu-single-question-container:last-child {
  margin-bottom: 20px;
}

.createform-menu-single-question-image-container {
  width: 40px;
  height: 40px;
  border-radius: 14px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createform-menu-single-question-text {
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #74798c;
  text-align: left;
  margin-left: 20px;
}

.createform-published-switch-label {
  font-family: 'NunitoSans Bold', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #393d52;
}

@media screen and (max-width: 800px) {
  .createform-menu-card-title {
    margin: 10px;
    text-align: center;
    line-height: 20px;
  }

  .createform-menu-container,
  .createform-menu-cards-container {
    width: 100px;
  }

  .createform-menu-container .ec-card {
    width: 100%;
  }

  .createform-menu-container .ec-card .ec-primary-button-text {
    display: none;
  }

  .createform-menu-single-question-text {
    display: none;
  }

  .createform-menu-single-question-container {
    margin: 10px auto;
  }

  .createform-menu-single-question-image-container {
    border-radius: 50%;
  }

  .createform-menu-single-question-container:hover {
    background: none;
  }

  .createform-menu-cards-container .ec-primary-button.ec-primary-button-left {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-height: 1000px) {
  .createform-menu-cards-container .ec-card {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  .createform-menu-cards-container .ec-card:first-of-type {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
  }

  .createform-menu-cards-container .createform-menu-card-title {
    display: none;
  }

  .createform-menu-cards-container .ec-primary-button.ec-primary-button-left {
    background-color: #ffffff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 0 auto 12px;
  }

  .createform-menu-cards-container .createform-menu-single-question-container {
    margin: 0 auto 6px 0;
  }

  .createform-menu-cards-container .createform-menu-single-question-image-container {
    background-color: #ffffff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }

  .createform-menu-cards-container,
  .createform-menu-cards-container .ec-card {
    width: 60px;
  }

  .createform-menu-cards-container .ec-primary-button-text,
  .createform-menu-single-question-text {
    display: none;
  }

  .createform-menu-cards-container .ec-primary-button.ec-primary-button-left {
    display: flex;
    justify-content: center;
    margin: 0 auto 10px 0px;
  }

  .createform-menu-container {
    width: 60px;
  }
}


@media (height < 770px) {
  .createform-menu-cards-container .ec-primary-button.ec-primary-button-left {
    width: 40px;
    height: 40px;
    margin: 0 auto 4px 0px !important;
  }                                
  .createform-menu-cards-container .createform-menu-single-question-image-container {
    width: 40px;
    height: 40px;
    margin: 2px;
  }
  .createform-menu-cards-container .createform-menu-single-question-container {
    margin: 0 auto 4px 0 !important;
  }
}

@media (height < 650px) {
  .createform-menu-cards-container .ec-card:first-of-type {
    margin-bottom: 2px;
  }
  .createform-menu-cards-container .createform-menu-single-question-container {
    margin: 0 auto 0px 0 !important;
  }
}