.customDatePicker,
.customDatePicker > div.react-datepicker-wrapper,
.customDatePicker > div > div.react-datepicker__input-container .customDatePicker > div > div.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container {
  width: inherit;
  height: inherit;
}

.react-datepicker__input-container input {
  width: 100%;
  border-radius: 8px;
  border: solid 1px #cfcfcf;
  background-color: #ffffff;
  outline: none;
  padding: 16px;
  text-align: left;
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #393d52;
  box-sizing: border-box;
}

.react-datepicker-wrapper {
  width: 100%;
}
