#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ec-question-basics-list {
  border: 1px solid hsl(0, 0%, 91%);
  border-radius: 4px;
  background-color: white;
  margin: auto;
  overflow: auto;
  padding: 8px 24px;
  height: 270px;
  width: 600px;
}

.ec-question-basics-tutor {
  color: #b1b1b1;
}

.ec-question-basics-keyword {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 0px;
}
