.ec-modal-buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
}

.ec-modal-buttons-firefox-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  margin-bottom: 40px;
}

.ec-modal-button-safari-text {
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 5px;
}

.ec-modal-button-first {
  background-color: #ffffff;
  width: 128px;
  height: 38px;
  border-radius: 8px;
  border-color: #ffffff;
  list-style-type: none;
  outline: none;
  border: solid 1.5px #ffffff;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ec-modal-button-first:hover {
  background-color: #eeeef1;
}

.ec-modal-button-second {
  width: 128px;
  height: 38px;
  border-radius: 8px;
  border: none;
  right: 52px;
  background-image: linear-gradient(252deg, #00d7e7, #00aecb);
  color: #fff;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ec-modal-button-second:hover {
  background-image: linear-gradient(255deg, #06c3d4, #06aac3);
}

.ec-modal-button-text {
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
