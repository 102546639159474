

/* React Select */
.ec-select-group_style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
}

.ec-select-group_style:hover {
  color: #00aecb;
}

.ec-select-group_style_disabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  color: grey;
  cursor: pointer;
}

.ec-group-badge-styles {
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
}

.ec-group-badge-styles_disabled {
  background-color: #ebecf0;
  border-radius: 2em;
  color: grey;
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
}

.reactSelect input:focus,
.reactSelect > input:focus {
  border: none;
  box-shadow: unset;
}
