.ec-question-pdf-container {
  margin-left: 10px;
  margin-bottom: 15px;
}

.ec-question-pdf-loading-text {
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 15px;
  margin-left: 4px;
}

.ec-question-pdf-buttons-container {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.ec-question-pdf-button {
  width: 50%;
}
