.ec-consentheader-description-tag-remove {
  border: none;
  background: none;
  color: #1890ff;
}

.ec-consentheader-description-tag-remove:after {
  content: 'x';
}

.ec-consentheader-description-tag > input {
  border: none;
  background: none;
  width: 0px;
}

.ReactTags__selected {
  display: flex;
}
