.ec-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: initial !important;
}

.ec-modal {
  margin-left: auto;
  margin-right: auto;
  padding: 44px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  width: 640px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  overflow-y: initial !important;
}

.ec-modal.full-width {
  min-width: 100%;
  max-width: 100%;
  width: 100vw;
}

.ec-modal.full-height {
  min-height: 100%;
  max-height: 100%;
  height: 100vh;
}

.ec-modal-scrollable {
  overflow-y: auto;
  -ms-overflow-style: thin; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
  padding-right: 20px;
  max-height: 90vh;
}

.ec-modal::-webkit-scrollbar {
  display: none; /* Chrome */
}

.ec-modal-contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

.ec-modal-contain h1 {
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.49;
  letter-spacing: -0.14px;
  color: #393d52;
}

.ec-modal-contain button {
  background-color: #ffffff;
  border-color: #ffffff;
  outline: none;
  border: solid 1.5px #ffffff;
}

@media only screen and (max-device-width: 1023px) {
  .ec-modal-scrollable {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media screen and (max-width: 640px) {
  .ec-backdrop {
    padding: 20px;
    box-sizing: border-box;
  }

  .ec-modal {
    width: 100%;
    padding: 15px;
  }

  .createPatient-Container-part2 {
    width: 100%;
  }

  .createSurvey-sousContainer .react-datepicker__input-container input {
    width: 100%;
    height: 68px;
    box-sizing: border-box;
  }

  .ec-modal-contain h1 {
    line-height: 24px;
    font-size: 24px;
  }
}
