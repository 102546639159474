.question-type-container input[type='text'] {
  /* border: solid 1px #00aecb; */
  height: 25px;
  font-size: 20px;
  width: 100%;
  border-radius: 8px;
}

.question-type-container input:focus {
  border-color: #00aecb;
  box-shadow: 0 0 1px #00aecb;
}

.input-radio-input-vertical {
  border: 1px solid #00aecb;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-radio-input-vertical span {
  position: absolute;
  color: #b6b6b6;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.input-radio-input input[type='checkbox'],
.input-radio-input-vertical input[type='checkbox'] {
  opacity: 0;
}
.input-radio-input input[type='checkbox']:checked + label,
.input-radio-input-vertical input[type='checkbox']:checked + label {
  background-color: #00aecb;
}

.input-radio-input input[type='checkbox']:checked + label:after .input-radio-input-vertical input[type='checkbox']:checked + label:after {
  opacity: 1;
}
