.custom-formV2 {
  fieldset {
    border: none;
  }

  &-control {
    label {
      font-family: 'NunitoSans Regular', sans-serif;
      font-size: 18px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #79828b;

      &.required:after {
        content: ' *';
        color: red;
      }
    }

    &-error-text {
      margin-left: 10px;
      margin-top: 0px;
      margin-bottom: 0px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      text-align: left;
      color: red;
    }
  }
}

.InputCheckbox-container {
  label > span {
    margin-left: 5px;
  }
}

.InputRadio-container {
  /* Radio */
  input[type='radio'] {
    background-color: white;
    border-radius: 10px;
    box-shadow: inset 0 1px 1px hsla(0, 0%, 100%, 0.8), 0 0 0 1px rgba(0, 174, 203, 0.8);
    cursor: pointer;
    display: inline-block;
    margin-right: 18px;
    position: relative;
    height: 18px;
    width: 18px;
    -webkit-appearance: none;
    &:after {
      background-color: white;
      border-radius: 25px;
      content: '';
      display: block;
      position: relative;
      top: 4px;
      left: 4px;
      height: 10px;
      width: 10px;
    }
    &:checked:after {
      background-color: #00aecb;
      box-shadow: inset 0 0 0 1px rgba(0, 174, 203, 0.8);
    }
  }

  margin-top: 12px;
  > label {
    margin-right: 39px;
  }

  &-vertical {
    display: flex;
    flex-direction: column;
    margin-left: 29px;
    &:first-child {
      margin-top: 13px;
    }
  }
}

.inputFormItem {
  &-container {
    display: flex;
    width: 100%;
    position: relative;

    &-icon {
      position: absolute;
      padding: 10px;
      color: white;
      min-width: 50px;
      right: 0px;
      height: 68px;
      width: 68px;
      /* content in the icon div is centered, without bootstrap or font-awesome you may wish to add your own text in the span */
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      > img {
        height: auto;
        width: 21px;
      }
    }

    .input {
      font-family: 'NunitoSans Regular', sans-serif;
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #cfcfcf;
      background-color: #fff;
      font-weight: 600;
      outline: none;
      color: #393d52;
      padding: 16px;
      padding-right: 68px;
      font-size: 20px;
      line-height: 1.7;
    }
  }
}

.InputArea {
  &-container {
    width: 100%;
  }
}

/* InputGroupedCheckbox */
.ec-checkbox-text {
  font-family: 'NunitoSans Regular', sans-serif;
}

.ec-checkbox-text span {
  margin-left: 5px;
}
