.createform-main-container {
  input[type='text'] {
    width: 100%;
    border: none;
    background-color: #ffffff;
    outline: none;
    font-family: 'NunitoSans Regular', sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: #20253a;
  }
}

.createform-body-container {
  input[type='text'] {
    width: 100%;
    border: none;
    background-color: #ffffff;
    outline: none;
    font-family: 'NunitoSans Regular', sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: #20253a;
  }
}
