.ec-primary-button {
  background: none;
  border: none;
  outline: none;
  display: flex;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  width: 221px;
  height: 45px;
  cursor: pointer;
}

.ec-primary-button img {
  width: 23px;
  height: 24px;
}

.ec-primary-button-center {
  align-items: center;
  justify-content: flex-start;
}

.ec-primary-button-left {
  align-items: center;
  justify-content: flex-start;
}

.ec-primary-button:hover {
  background-color: #f8f8f8;
  border-radius: 8px;
}

.ec-primary-button-active {
  background-color: #f8f8f8;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  width: 221px;
  height: 45px;
}
.ec-primary-button:last-child,
.ec-primary-button-active:last-child {
  margin-bottom: 0px;
}

.ec-primary-button-hidden {
  opacity: 0.3;
}

.ec-primary-button-text {
  font-family: 'NunitoSans Bold', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #393d52;
  margin-left: 30px;
  margin-top: auto;
  margin-bottom: auto;
}
