.consent-step-dropzone-container {
}
/* Dropzone.jsx */
.consent-step-dropzone-container {
  height: 75px;
  /*opacity: 0.07;*/
  border-radius: 14px;
  border: dashed 3px rgba(0, 0, 0, 0.07);
  background-color: rgba(222, 222, 222, 0.07);
  margin-left: 28px;
  margin-bottom: 28px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.consent-step-dropzone-container-text {
  font-family: 'NunitoSans Regular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.13;
  letter-spacing: normal;
  color: #79828b;
  margin-bottom: 0px;
  z-index: 1;
}
