.question-type-container {
  width: 100%;
}

.question-type-image-container {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* onMoreInfo.jsx */
.question-type-on-more-info-container {
  position: absolute;
  z-index: 1;
  right: 0;
  max-width: 500px;
}

.question-type-question-inside-container input[type='text'] {
  font-size: 18px;
  font-weight: normal;
}
